
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import ImageOne from './Images/FirstPic.jpg';
import ImageTwo from './Images/SecondPic.jpeg';
import ImageThree from './Images/ThirdPic.jpg';
import ImageFour from './Images/FourthPic.jpg';
import ImageFive from './Images/FifthPic.jpg';
import ImageSix from './Images/SixthPic.jpg';
import ImageSeven from './Images/SeventhPic.jpg';
import ImageEight from './Images/EighthPic.png';
import ImageNine from './Images/NinthPic.jpg';
import ImageTen from './Images/TenthPic.jpg';
import ImageEleven from './Images/EleventhPic.jpg';
import ImageTwelve from './Images/TwelfthPic.jpg';
import ImageThirteen from './Images/ThirteenthPic.jpg';
import ImageFourteen from './Images/FourteenthPic.jpg';

import ImageFifteen from './Images/FifteenthPic.jpg';

import ImageSixteen from './Images/SixteenthPic.jpg';

import ImageSeventeen from './Images/SeventeenthPic.jpg';

import ImageEighteen from './Images/EighteenthPic.jpg';

import ImageNineteen from './Images/NineteenthPic.jpg';

import ImageTwenty from './Images/TwentiethPic.jpg';

import ImageTwentyOne from './Images/TwentyFirstPic.jpg';

import ImageTwentyTwo from './Images/TwentySecondPic.png';

import ImageTwentyThree from './Images/TwentyThirdPic.jpg';

import ImageTwentyFour from './Images/TwentyFourthPic.jpg';


import ImageTwentyFive from './Images/TwentyFifthPic.jpg';


import ImageTwentySix from './Images/TwentySixthPic.jpg';

import Polaroid from './Images/polaroid.png';

import './DecJanFebSS.css'












const DecJanFeb = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };




  return (
    <div className='body'>
      <div style={{ display: 'block', width: 1000, paddingLeft: 450, paddingTop: 20, paddingBottom: 40 }}>
        <h4 style={{ fontFamily: 'Lucida Console' }}>Us so far...</h4>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Carousel className='carousel'>
            <Carousel.Item>

              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={840}
                height={640}
                src={ImageOne}
                alt="Image One"
              />
              <Carousel.Caption>
                <h1 style={{ fontFamily: 'Lucida Console', color: 'white' }}> December 16th</h1>
                <h4 style={{ fontFamily: 'Lucida Console', color: 'white' }}>The night of your very first message. I had no idea how my life was about to change.</h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={840}
                height={640}
                src={ImageFour}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h1 style={{ fontFamily: 'Lucida Console', height: 150, paddingRight: 100, color: 'white' }}>Moving off instagram...</h1>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={740}
                height={640}
                src={ImageFive}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 250, paddingRight: 100, color: 'white' }}>It didn't take me long to realize I loved making you laugh</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={640}
                height={640}
                src={ImageSix}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 105, color: 'white' }}>And how funny you were...</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={800}
                height={600}
                src={ImageSeven}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', paddingRight: 100, height: 60, color: 'white' }}>It didn't take you long to realize how much I liked you</h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={740}
                height={600}
                src={ImageEight}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 260, color: 'white' }}>And that you liked me</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={740}
                height={640}
                src={ImageNine}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 370, color: 'white' }}>And that we liked each other</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={500}
                height={640}
                src={ImageTen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 160, color: 'white' }}>We had our first date. I remember seeing you in front of Arms and trying not to throw up</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={640}
                src={ImageEleven}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h2 style={{ fontFamily: 'Lucida Console', height: 140, color: 'white' }}> And then it was Febuary. All I wanted was to be around you </h2>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={480}
                height={640}
                src={ImageTwelve}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 100, color: 'white' }}> And then it was March, and we were in love</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={530}
                height={640}
                src={ImageThirteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 240, color: 'white' }}> April came and went. I learned what it meant to have someone hold my heart </h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={480}
                height={640}
                src={ImageFourteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 240, color: 'white' }}> May came too fast. I didn't want to leave you. </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={468}
                height={640}
                src={ImageFifteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 150, color: 'white' }}> But I was able to come see you over the summer. I didn't know it was possible to miss someone so much </h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={640}
                src={ImageSixteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 150, color: 'white' }}> Even if it wasn't all smooth sailing</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={400}
                height={640}
                src={ImageSeventeen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 150, color: 'white' }}> July! We got to have a week of living the dream together</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={500}
                height={640}
                src={ImageEighteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 140, color: 'white' }}> August. The longest time apart finally over. Three more months together. I was estatic. </h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={500}
                height={640}
                src={ImageNineteen}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 220, color: 'white' }}> September was crazy. But you still managed to plan the best surprise for my birthday. </h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={500}
                height={640}
                src={ImageTwenty}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 150, color: 'white' }}> And then we got to spend a LOT of time together. </h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={500}
                height={640}
                src={ImageTwentyOne}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 50, color: 'white' }}>October zoomed by. I treasured every second I had with you. </h4>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={600}
                src={ImageTwentyTwo}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 100, color: 'white' }}>November came too soon. My surgery was coming up. I didn't want to leave you. Though this picture made me feel better. </h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={600}
                src={ImageTwentyThree}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h2 style={{ fontFamily: 'Lucida Console', paddingLeft: 100, height: 100, fontWeight: 'bold', color: 'maroon' }}>The hospital whooped my ass!!</h2>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={400}
                height={600}
                src={ImageTwentyFour}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 style={{ fontFamily: 'Lucida Console', height: 100, color: 'white' }}>But you drove eight hours to take care of me. I still can't believe how lucky I am. </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={600}
                src={ImageTwentyFive}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 50, color: 'white' }}>New years came. I was so grateful to be able to kiss you at midnight.</h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                style={{ borderLeft: '8px solid silver', borderRight: '8px solid silver', borderTop: '8px solid silver', borderBottom: '30px solid silver' }}
                width={470}
                height={600}
                src={ImageTwentySix}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h4 style={{ fontFamily: 'Lucida Console', height: 50, color: 'white' }}>Back to campus we went! To work that horrific ass summit</h4>
              </Carousel.Caption>
            </Carousel.Item>


          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default DecJanFeb;