import React, { useState } from 'react';
import PlaceHolder from './Images/PlaceHolder.png';
import FreeImage from './Images/FreeQuote.png';
import BryceImage from './Images/BrycePic.png';
import EnelyPic from './Images/EnelyPic.jpg';
import AdriPic from './Images/AdriPic.jpg';
import JingPic from './Images/JingPic.jpg';
import JanePic from './Images/JanePicFour.png';
import IyancyPic from './Images/IyancyPic.jpg';
import JosephPic from './Images/JosephPic.jpg';
import SydPic from './Images/SydPic.jpg';
import DaniaPic from './Images/DaniaPic.jpg';
import EvaPic from './Images/EvaPic.jpg';
import BelemPic from './Images/BelemPic.jpg';
import NevPic from './Images/NevPic.jpg';
import CarolinaPic from './Images/CarolinaPic.jpg';
import JordanPic from './Images/JordanPic.jpg';
import ErxiPic from './Images/ErxiPic.jpg';








const reviews = [
  {
    name: 'Free',
    imagesource: FreeImage,
    review: '“Logan is so perfect for Syd, but more importantly, for the rest of us, because we have no other good role models here. I’m not exaggerating when I say they’re one of the most caring, thoughtful, well adjusted people I’ve met in a long time.”',
    width: 500,
    height: 400

  },
  {
    name: 'Bryce',
    imagesource: BryceImage,
    review: 'Logan, Happy Anniversary to you and Syd!! It’s been a year of watching my favorite love story blossom hehe. I am constantly amazed and grateful for the love and care you show both Syd and the entire gang! We love you so much. You are truly such a special person with a huge heart and your energy is bright, radiant, and above all inspiring to me. I still remember when Syd was scared to talk to you and I just had such a good, bubbly feeling and here we are! The way you love makes me believe in love. Thank you for all the smiles and laughs, memories, advice, and just for being you! I am your biggest fan and always always rooting for you in everything you do. So much love, Bryce <3 ',
    width: 500,
    height: 400

  },

  {
    name: 'Enely',
    imagesource: EnelyPic,
    review: 'logan, throughout these four years, you have been such an amazing friend and light in my life. you hold so much love and compassion and it makes everything feel so important (because everything is!!). you never cease to amaze me and every opportunity we get to spend time together, laugh, and make more memories feels like such a blessing. you are so kind, and beautiful, and intelligent and absolute perfection in a bottle. thank you for being such a joy. i hope you always know how loved you are and how much of a privilege it is to be loved by you. 5 stars! will definitely book again!',
    width: 380,
    height: 430

  },

  {
    name: 'Adri',
    imagesource: AdriPic,
    review: 'Logang 😫 in the little time I have of knowing you I have grown so fond of you you’re so sweet and thoughtful I am so glad Syd has you by her side I can’t think of anyone better for my best friend than you ❤️',
    width: 500,
    height: 400

  },

  {
    name: 'Jing',
    imagesource: JingPic,
    review: 'Meeting a person like Logan it’s truly a once in a lifetime event because they are such an incredible human being from their compassion and intellect (and also how goddamn funny they are), so for me to be able to call them one my closest friends truly a blessing and means everything to me ',
    width: 400,
    height: 450

  },

  {
    name: 'Jane',
    imagesource: JanePic,
    review: '5 Reasons I Would NOT Recommend Being Friends With Logan!!!!! First, Logan is too passionate! If you don’t want to be influenced by the strength of their conviction and love for art, literature, writing, friends, family, and lovers to feel a bit of that yourself, then I really cannot recommend them for you. Second, Logan is TOO GOOD of a listener. Every time I call them I feel taken into account. I feel respected, held at eye level. Imagine my voice in the phone, but imagine I’m a smaller version of myself. Logan holds me up to their ear and hears every word. Then my words take on meaning, take on shape, as they come out of mouths and pass through minds. That quality is so hard to find and Logan is unfortunately overflowing with it. Third, Logan doesn’t like chocolate. Fourth, Logan encapsulates wayyy too much beauty. Pictures for reference. It’s like looking into the sun and I’m not a sunglasses wearer so this just doesn’t work for me. Fifth, you’ll never want to leave their side. I mean imagine you’re glued to them forever. IMAGINE. Through pure will of your own.',
    width: 310,
    height: 430

  },

  {
    name: 'Iyancy',
    imagesource: IyancyPic,
    review: 'Logan is that one person anyone would be considered lucky to meet in their lifetime. They are the toughest, most kind-hearted, most hardworking, competitive, funniest, and absolutely brilliant person anyone will ever encounter. Yet, these words are not enough to fully encapsulate how amazing they are. Logan truly deserves every moment of happiness they experience, and I hope these moments only grow for them. Side note their laugh is one of my favorites. ',
    width: 330,
    height: 430

  },

  {
    name: 'Joseph',
    imagesource: JosephPic,
    review: 'There are not enough words in the English language to describe someone so incredibly unique, caring, compassionate, heartwarming, and so self-established beyond their years. Logan you are truly an old soul; as of now you may be twenty-one years old but, you possess the inspiring wisdom and mental fortitude of an old soul. You inspire me to be my best self, to not settle for less, and to go above and beyond. I am incredibly grateful for you, the enriching and enlightening ambiance you bring to your surroundings, and, above all, the happiness and breath of fresh air you bring to my sister—Sydney. You two are an immaculate blend of perfection, you complement each other so well, and a reminder to me and the world that authentic love exists on our humanely realm. Getting to know you was one of the greatest honors I’ve had; I adore that whoever you let into your life grows alongside—you strive for greatness and that excellence radiates all around you. Whenever I think of you and Sydney my heart fills with so much joy—I’ve never seen a more true blueprint. I am so incredibly happy to have seen your relationship start off from a kiss blown in passing to a partnership written in the heavens above. I love that whenever I have a problem I can come to you—Logan, you truly listen with the intention of understanding, to validate, to guide, and to appreciate emotionally. I am so glad you have chosen me to be one of those recipients. Logan, never change. Be your authentic self, continue to make those around you happy, continue to make Sydney happy, and, continue to make yourself happy. I love you.',
    width: 340,
    height: 430

  },

  {
    name: 'Syd',
    imagesource: SydPic,
    review: "Of course I had to write a review! First of all: Five stars. Ten, actually. A billion stars!! I love you so so so much. Thank you so much for being a part of my life. I love you more than anything ever. You make me so, so happy, and I would literally lasso the sun and give it to you if you asked. I love everything about you, from your beautiful (stunning, jaw-dropping perfect) face to your absolutely angel-made laugh (seriously!!! I love the way you laugh so much) to everything in between. You're an amazing partner, an amazing friend, and I love you so much. Thank you for being a part of my life",
    width: 340,
    height: 430

  },


  {
    name: 'Dania',
    imagesource: DaniaPic,
    review: "Logan is an essential part of everyone’s life. You cannot find someone more committed, quick-witted, passionate, and efficient as them. If I had Logan’s attributes, I would be self-centered as fuck and have a gigantic ego, but they manage to be humble, caring, considerate, and funny. They might try to not “be in the photo” to let you have your spotlight and shine. Their loyalty, thoughtfulness, and kindness will make every funny joke or entertaining interaction that much more valuable. They’re the kind of friend that lifts you up without even trying, and for that, them being in the photo is the fuel that lets your light glow. Logan, I am so grateful I met you. I love you so much, and may you always be in the photo of my life. Syd, thank you for cherishing this treasure!",
    width: 340,
    height: 430

  },

  {
    name: 'Eva',
    imagesource: EvaPic,
    review: "Hellooo Logan dear :) I will always be so grateful we got to bond during that choral society orientation treasure hunt-journey-thing during my first semester at Amherst and that I got to see you every Wednesday and Friday for what is still the best class I took at Amherst<3 I'm so glad we grew closer every semester after and that I got to have such a brilliant and beautiful friend to make such lovely memories with during my time as a mammoth! You are one of the kindest and funniest people I know; and oh so very talented; I miss and love you lots and hope you're able to do all the things that bring your heart joy!! Wishing you and Sydney such a happy anniversary!!!! Much love, Eva",
    width: 340,
    height: 430

  },

  {
    name: 'Belem',
    imagesource: BelemPic,
    review: "Hey Logan, You beautiful human being. I'm so glad we met and got to spend time together. There wasn't a moment that I did not enjoy when you were there. From the drunken basement parties, to the crazy night in New Orleans followed by thee worst hangover everr, and your super complex card games, and reading your awesome writing ... you were a friendship I did not expect to find but that I am so glad I have. I will spend the rest of my days convincing you to come to Chicago out of my own selfish needs. Because life is better with you in it. Happy anniversary with the love of your life! Sad is such an amazing person and her love for you is so sweet. Look at her doing this romantic gesture. You deserve that love and more. Congrats to the both of you 💗💗",
    width: 340,
    height: 430

  },

  {
    name: 'Neviah',
    imagesource: NevPic,
    review: "humor 10/10 friendship 10/10 fun 10/10 \n Logan is a forever friend and one of my favorite people in the whole world! Seeing them is always such a special treat and they never fail to brighten my day. Logan read this and know that you are so loved 💖",
    width: 340,
    height: 430

  },

  {
    name: 'Carolina',
    imagesource: CarolinaPic,
    review: "Logan, you are a friend I want in my life forever. I am so so grateful for your presence in my life, always bringing me joy and peace, and reminding me that growth, although scary, can be exciting. You are a friend I can always rely on. You have gotten me through some really tough times and for that, I am forever grateful. You have helped me be a better communicator and have supported all my intention-setting activities. There is no one I’d rather journal with! There is so much more I can say but I’ll leave it here! I love you so so much Logan!!",
    width: 340,
    height: 430

  },


  {
    name: 'Jordan',
    imagesource: JordanPic,
    review: "It was on a zoom screen, the spring of our first year that I first met Logan. Yes, I learned a lot about women and religion in greek and rome, but the most important thing I got out of that class was a caring, understanding, and always funny friendship. This bright summer day at puffer's with you was one of my favorite days since I got here, and I'm so excited for many more to come!",
    width: 300,
    height: 430

  },

  {
    name: 'Erxi',
    imagesource: "",
    review: "10/10 friend, English enthusiast, and fellow thesis sufferer. Who would have though that out of everyone on our Soliya, it would have been the two of us that became and stayed friends? I would like to say that I called it all along, but I've benefitted greatly from hindsight. I can't wait to spend more time with you and take a photo together, so that the space above wouldn't be empty.",
    width: 300,
    height: 430

  },



];

const ReviewList = () => (
  <div>
    {reviews.map(review => (
      <Review key={review.name} name={review.name} imagesource={review.imagesource} review={review.review} width={review.width} height={review.height} />
    ))}
  </div>
);


const Review = ({ name, imagesource, review, width, height, imagesource2 }) => (
  <div>
    <h2 style={{ fontWeight: 'bolder', fontFamily: 'Lucida Console', paddingTop: 30, paddingBottom: 20 }}>From: {name}</h2>
    <img
      border="5"
      width={width}
      height={height}
      src={imagesource}
    />
    <h4 style={{ fontWeight: 'bold', fontFamily: 'Lucida Console', paddingLeft: 20, paddingRight: 40, paddingTop: 30, paddingBottom: 40 }}>Review: {review}</h4>
  </div>
);

export default ReviewList;
