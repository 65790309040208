
import { useState, useEffect } from 'react';
import { format, formatDuration, formatDistance, formatRelative, subDays, intervalToDuration } from 'date-fns'




const SeptOctNov = () => {
  const [time, setTime] = useState("I love you!");

  useEffect(() => {
    const interval = setInterval(() => {

      setTime(intervalToDuration({
        start: new Date(2023, 1, 12, 0, 0, 0),

        end: new Date()
      }));
    }, 0);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ height: 750 }} className='bodyJ'>
      <h1 style={{ fontFamily: 'Lucida Console', paddingTop: 30 }} >It's been:</h1>
      <h1 style={{ fontFamily: 'Lucida Console', paddingTop: 30 }} >{formatDuration(time)}</h1>
      <h1 style={{ fontFamily: 'Lucida Console', paddingTop: 30 }} >Since we started dating!</h1>
    </div>


  );
};

export default SeptOctNov;