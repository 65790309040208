
import ImageOne from './Images/FebPicOne.jpg';
import ImageTwo from './Images/FebPicTwo.jpg';
import ImageThree from './Images/FebPicThree.jpg';
import ImageFour from './Images/FebPicFour.jpg';
import ImageFive from './Images/FebPicFive.jpg';
import ImageSix from './Images/FebPicSix.jpg';
import ImageSeven from './Images/FebPicSeven.jpg';
import ImageEight from './Images/FebPicEight.jpg';
import ImageNine from './Images/FebPicNine.jpg';
import ImageTen from './Images/FebPicTen.jpg';
import ImageEleven from './Images/FebPicEleven.jpg';
import ImageTwelve from './Images/FebPicTwelve.jpg';


const Feb = () => {
  return (

    <div className='bodyJ'>
      <h2 style={{ fontFamily: 'Lucida Console', paddingTop: 30, paddingBottom: 30 }}>Our anniversary in photos!</h2>


      <figure>
        <img style={{ border: '2px solid #000' }} width={450} height={500} src={ImageOne}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30, paddingBottom: 30 }}>Beginning of the day. We got lunch and then did work! I took like thirty pictures of Logan here.</figcaption>
      </figure>

      <figure>
        <img style={{ border: '2px solid #000' }} width={450} height={500} src={ImageTwo}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>I wanted a picture of our faces, so I did a dance to distract Logan and then took this photo</figcaption>
      </figure>

      <figure>
        <img style={{ border: '2px solid #000' }} width={450} height={500} src={ImageThree}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>We went to Build-A-Bear! We got to dress the bears. This is Logan and Bear Logan. To me, they look identical.</figcaption>
      </figure>

      <figure>
        <img style={{ border: '2px solid #000' }} width={445} height={500} src={ImageFour}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Me and my bear. You'll notice my bear doesn't look like me, and that's because her outfit is mid. </figcaption>
      </figure>

      <figure>
        <img style={{ border: '2px solid #000' }} width={445} height={500} src={ImageFive}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Bear Logan didn't mind though! They still held Bear Syd's paw. Very cute.</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={445} height={500} src={ImageSix}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>We went to go explore the mall, which is where I tried to get a candid of Logan walking. I think they look great here!</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={445} height={500} src={ImageSeven}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Off to dinner! Logan did their makeup perfectly in the car and I caught this stunning view. And a nice sunset!</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={400} height={500} src={ImageEight}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Appetizer first! We got calamari. The squid bodies freak me out, but they were tasty. Logan loved it!</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={400} height={500} src={ImageNine}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>WOOOOO YEAHHHH WOOOOOOO</figcaption>
      </figure>

      <figure>
        <img style={{ border: '2px solid #000' }} width={400} height={500} src={ImageTen}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>My food!! Steak with gorgonzola and potatoes, plus Logan's green beens that I ate. Very good.</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={400} height={500} src={ImageEleven}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Logan's food! A pork dish. Logan liked it a lot. I had some, I really liked it. This picture does not do it justice.</figcaption>
      </figure>
      <figure>
        <img style={{ border: '2px solid #000' }} width={400} height={500} src={ImageTwelve}></img>
        <figcaption style={{ fontFamily: 'Lucida Console', fontSize: 20, paddingTop: 30 }}>Dessert!! A lovely end to a lovely day.</figcaption>
      </figure>
    </div >


  );




};


export default Feb;