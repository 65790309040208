import logo from './logo.svg';
import './App.css';
import DecJanFeb from './DecJanFeb'
import Home from './Home'
import MarchAprMay from './MarchAprMay'
import JuneJulyAug from './JuneJulyAug'
import SeptOctNov from './SeptOctNov'
import DecemberJanuary from './DecemberJanuary';
import Feb from './Feb'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar> </Navbar>
        <Routes>
          <Route path="/" element={<DecJanFeb />} />
          <Route path="Home" element={<DecJanFeb />} />
          <Route path="LoganOnly" element={<DecemberJanuary />} />
          <Route path="PictureFinder" element={<MarchAprMay />} />
          <Route path="LoganReviews" element={<JuneJulyAug />} />
          <Route path="HowLong" element={<SeptOctNov />} />
          <Route path="Feb12" element={<Feb />} />
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
