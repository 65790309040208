
import { DateCalendar, DatePicker, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect, useState } from "react";


const MarchAprMay = () => {
  const [index, setIndex] = useState();
  const [currDate, setDate] = useState(new Date());
  const [borderSize, setBorderSize] = useState('0px solid black');

  const dates = [];
  const [date, setDateText] = useState('Pick a date!');
  function importAll(r) {
    const tempImages = r.keys().map(r)
    for (var i = 0; i < tempImages.length; i++) {
      var date = tempImages[i].toString()
      date = date.substr(14, 10)
      date = date.replace(':', '/')
      date = date.replace(':2023', '/2023')
      date = date.replace(':2024', '/2024')
      const newDate = new Date(date)
      dates.push(newDate)
    }
    return r.keys().map(r);
  }

  const images = importAll(require.context('./Images/DatePhotos', false, /\.(png|jpe?g|svg)$/));


  const handleClick = () => {
    var smallestDiff = Number.MAX_SAFE_INTEGER;
    var indexOfDiff = 0;
    for (var i = 0; i < dates.length; i++) {
      var diffTime = Math.abs(currDate - dates[i]);
      if (diffTime < smallestDiff) {
        smallestDiff = diffTime;
        indexOfDiff = i
      }
    }
    setDateText('The closest photo to that date is: ' + dates[indexOfDiff].toDateString());
    setIndex(indexOfDiff);
  };

  const dateSelected = (date, event) => {

    setDate(date)
    var smallestDiff = Number.MAX_SAFE_INTEGER;
    var indexOfDiff = 0;
    for (var i = 0; i < dates.length; i++) {
      var diffTime = Math.abs(currDate - dates[i]);
      if (diffTime < smallestDiff) {
        smallestDiff = diffTime;
        indexOfDiff = i
      }
    }

    console.log(dates[indexOfDiff]);


  };



  useEffect(() => {


  }, []);

  return (
    <div className='bodyJ' style={{ height: 1500 }}>
      <h1 style={{ fontFamily: 'Lucida Console', paddingTop: 10 }} >Select a date and hit the button!</h1>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar onChange={(date, event) => { dateSelected(date, event) }} ></DateCalendar>
      </LocalizationProvider>
      <button type="button" onClick={handleClick}>Find a photo </button>
      <h1 style={{ fontFamily: 'Lucida Console', paddingTop: 30 }}>{date} </h1>
      <div style={{ fontFamily: 'Lucida Console', paddingTop: 30, paddingBottom: 60 }}>
        <img style={{ borderLeft: '8px solid black', borderRight: '8px solid black', borderTop: '8px solid black', borderBottom: '8px solid black' }} src={images[index]}></img>
      </div>


    </div >
  );
};

export default MarchAprMay;