
import React from 'react';
import { useState } from 'react';
import Review from './Review';
import PlaceHolder from './Images/PlaceHolder.png';
import './JuneJulyAug.css'; // Tell webpack that Button.js uses these styles

const JuneJulyAug = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };



  return (
    <div className='bodyJ'>
      <div>
        <h3 style={{ fontFamily: 'Lucida Console', paddingTop: 30 }}>You know I love you. But don't just take it from me!</h3>
        <Review></Review>
      </div>
    </div>
  );
};

export default JuneJulyAug;