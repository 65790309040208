import React from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavBarElements";

const Navbar = () => {
  return (
    <>
      <Nav>

        <NavMenu >
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/Home" >
            A quick recap...
          </NavLink>
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/PictureFinder" activeStyle>
            Picture Finder
          </NavLink>
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/LoganReviews" activeStyle>
            Logan Reviews
          </NavLink>
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/HowLong" activeStyle>
            How long has it been?
          </NavLink>
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/LoganOnly" activeStyle>
            Logan only!!
          </NavLink>
          <NavLink style={{ color: "silver", fontFamily: 'Lucida Console' }} to="/Feb12" activeStyle>
            Feb12
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;